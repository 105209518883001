.dashboard__weekly-stat {

  hr {
    margin-bottom: 20px;
  }

  &-chart-normal {
    display: flex;
    margin: 0;
    justify-content: space-around;

    .recharts-surface {
      width: 100%;
      height: 100%;
    }

    &-item {
      width: 100%;
      max-width: 213px;

      @media screen and (max-width: 370px) {
        margin-left: 10px;
      }

      @media screen and (min-width: 1200px) {
        margin-left: 10px;
      }

      @media screen and (min-width: 1400px) {
        margin-left: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &-pie {
      display: block;
      position: relative;
      text-align: center;
      height: 196px;

      .recharts-responsive-container {
        width: 100% !important;
      }
    }

    &-pie-wrapper {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &-chart-medium {
    display: flex;
    margin: 0 0 8px 0;
    justify-content: space-around;

    .recharts-surface {
      width: 100%;
      height: 100%;
    }

    &-item {
      width: 100%;
      max-width: 180px;

      @media screen and (max-width: 370px) {
        margin-left: 10px;
      }

      @media screen and (min-width: 1200px) {
        margin-left: 10px;
      }

      @media screen and (min-width: 1400px) {
        margin-left: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &-pie {
      display: block;
      position: relative;
      text-align: center;
      height: 180px;

      .recharts-responsive-container {
        width: 100% !important;
      }
    }

    &-pie-wrapper {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &-label {
    position: absolute;
    top: calc(50% - 2.5em);
    width: 100%;
    margin: 0;
    animation: label 1.5s ease-in;
    font-size: 3.7em;
    line-height: 5em;
    font-weight: 800;
  }

  &-info {
    font-size: 11px;
    line-height: 1.38;
    text-align: center;
    position: absolute;
    width: 100%;
    top: calc(50% + 28px);
    p {
      color: $color-additional;
      letter-spacing: 5px;
    }
  }
}

.mobile-app-widget {
  margin: 3px 0px 3px 0px;

  .mobile-app-widget__top-line {
    display: flex;

    .mobile-app-widget__total-stat {
      padding: 0;
      font-size: 28px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-right: auto;
      line-height: normal;
    }
  }

  .mobile-app-widget__top-line--pink {
    .mobile-app-widget__total-stat {
      color: $color-red;
    }
  }

  .mobile-app-widget__top-line--lime {
    .mobile-app-widget__total-stat {
      color: #b8e986;
    }
  }

  .mobile-app-widget__top-line--blue {
    .mobile-app-widget__total-stat {
      color: #48b5ff;
    }
  }

  .mobile-app-widget__top-line--turquoise {
    .mobile-app-widget__total-stat {
      color: #4ce1b6;
    }
  }

  .mobile-app-widget__title {
    text-transform: uppercase;
    position: relative;
    margin: 0 0 5px;

    h5 {
      font-size: 14px;
      font-weight: 800;
      letter-spacing: .5px;
      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  .progress__label {
    font-size: 21px;
    font-weight: 900;
  }

  .progress__label {
    right: -7px;
  }

  .progress-bar {
    border-radius: 5px;
    background-color: unset;
  }

}