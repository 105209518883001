.btn {
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-right: 15px;
 // transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $color-accent;
    //transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  p {
    display: flex;
    //transition: all 0.3s;
    font-weight: 500;
    color: #fffdfd;
  }

  &:hover, &:focus, &:active, &:active:focus {
    outline: none;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus, &:active, &:active:focus {

    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.icon {
    padding: 10px 15px;

    &:hover {

      p {
        color: #f3f4fc;
      }

      svg {
        fill: #fbfbff;
      }
    }

    &.icon--right {

      svg {
        margin: 2px 0 0 5px;
      }
    }
  }

  .btn-sm {
    padding: 5px 25px;
    font-size: 14px;
  }

  &.btn-lg {
    padding: 12px 25px;
    font-size: 14px;
  }
&.btn-secondary {
    background-color: #263237;
    border-color: #e7e2e2;
    color: #fdfdfd;
    // top: 30%;
    
    &:before {
      background-color: darken(#263237, 10%);
    }
  
    &:hover, &:focus, &:active, &:active:focus {
      border-color: darken(#263237, 10%);
      color: #f7f8f8;
    }
  
    &:not([disabled]):not(.disabled):active, &:not([disabled]):not(.disabled).active {
      background-color: #dddddd;
      border-color: #dddddd;
    }
      svg {
    height: 14px;
    width: 14px;
    margin: 2px 5px 0 0;
    transition: all 0.3s;
    fill: #fafbfd;
  }

  }

&.btn-outline-secondary {
    border-color: #ffffff;
    border: none;
    &, p {

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    svg {

      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    &:before {
      background-color: #ffffff;
    }

    &:hover, &:focus, &:active, &:active:focus {
      background: transparent;
      border-color: #ffffff;
      border: none;
      color: #2854ad;

      p {
        color: #ffffff;
      }

      svg {
        fill: #e3dada;
      }
    }
  }

&.btn-primary {
    background-color: #121858;
    border-color: #e7e2e2;
    color: #ffffff;
    // top: 30%;
    &:before {
      background-color: darken(#0e1883, 10%);
    }
  
    &:hover, &:focus, &:active, &:active:focus {
      border-color: darken(#285192, 10%);
      color: #fff;
    }
  
    &:not([disabled]):not(.disabled):active, &:not([disabled]):not(.disabled).active {
      background-color: #dddddd;
      border-color: #dddddd;
    }
    svg {
      height: 14px;
      width: 14px;
      margin: 2px 5px 0 0;
      transition: all 0.3s;
      fill: #fafafa;
    }
  
  }

&.btn-outline-primary {
    border-color: #0061B3;
    &, p {

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    svg {

      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    &:before {
      background-color: #e7e2e2;
    }

    &:hover, &:focus, &:active, &:active:focus {
      background: transparent;
      border-color: #e7e2e2;
      color: #2854ad;
      p {
        color: #f4f2f2;
      }

      svg {
        fill: #f3ebeb;
      }
    }
  }

  &.btn-success {
    background-color: $color-green;
    border-color: $color-green;

    &:before {
      background-color: $color-green-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-green-hover;
    }
  }

  &.btn-outline-success {
    color: $color-green;
    border-color: $color-green;

    p {
      color: $color-green;
    }

    svg {
      fill: $color-green;
    }

    &:before {
      background-color: $color-green;
    }
  }

  &.btn-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: #ffffff;

    &:before {
      background-color: $color-yellow-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-yellow-hover;
    }
  }

  &.btn-outline-warning {
    color: $color-yellow;
    border-color: $color-yellow;

    p {
      color: $color-yellow;
    }

    svg {
      fill: $color-yellow;
    }

    &:before {
      background-color: $color-yellow;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border-color: $color-red;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-red-hover;
    }
  }

  &.btn-outline-danger {
    color: $color-red;
    border-color: $color-red;

    p {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }

    &:before {
      background-color: $color-red;
    }
  }

  &, &.btn-danger, &.btn-warning, &.btn-success,
  //  &.btn-outline-secondary, 
  &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success,
  &.icon {

    &.disabled {
      background-color: #f2f4f7;
      border-color: #f2f4f7;
      color: #dddddd;
      pointer-events: none;

      p {
        color: #fcfcfc;
      }

      svg {
        fill: #dddddd;
      }

    }
  }

   &.btn-danger, &.btn-warning, &.btn-success {
    p {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }

   &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success {

    &:hover, &:focus, &:active, &:active:focus {
      color: #ffffff;
      background: transparent;
    }
  }

  &.btn-danger, &.btn-warning, &.btn-success,
  &.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success {

    &:hover, &:focus,
    &:active, &:active:focus {

      p {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }

  }

  &.expand {

    svg {
      width: 0;
      transition: all 0.3s;
    }

    &.expand--load {

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      svg {
        width: 14px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}

.btn-toolbar {
  margin-top: 15px;
  margin-bottom: 10px;

  & > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  &.btn-toolbar--center {

    & > * {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  border-radius: 5px;
  margin-bottom: -10px;

  .btn {
    margin-right: 0;
    padding: 10px 15px;
    font-weight: 500;
  }

  &.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.btn-group--icons {

    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

button:focus, button:active {
  outline: none;
}

.open > .dropdown-toggle.btn-default,
.btn-default:active:focus, .btn-default:active:focus,
.btn-default.focus, .btn-default:focus {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.custom-pagination {
  min-height: 52px;
  padding-right: 2px;
  display: flex;
  float: right;
  align-items: center;

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.6;
    text-align: left;
}
&.custom-pagination p {
  font-family: Arial, sans-serif; /* Cambia la fuente según tus preferencias */
  font-size: 14px; /* Cambia el tamaño de fuente según tus preferencias */
  font-weight: bold; /* Cambia el peso de la fuente según tus preferencias */
}
&.custom-pagination select,
&.custom-pagination p,
&.custom-pagination .pagination-button {
  margin: 8px; /* Ajusta el margen entre los elementos según tus preferencias */
  justify-content: space-between;
}

  &.root{
    display: flex;
    position: relative;
    align-items: center;
  }

  &.pagination-button {
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border-bottom: transparent;
  }
  &.pagination-button:hover {
    background-color: transparent;
  }
  &.pagination-select{
    border-color: transparent;
    padding-right: 20px;
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: currentColor;
    min-width: 16px;

  &.pagination-select:hover{
        background-color: transparent;
        cursor: pointer;
      }
  }
}
.pagination-bottom{
  border: 1px solid #dfdede;
}
