@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: 'Product Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  & small {
    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

.btn-rounded {
  border-radius: 33px !important;
}

.no-radius {
  border-radius: 0 !important;
}

.detallesCard {
  background: white;
  padding: 21px;
  // min-height: calc(100vh - 60px);

  & h3 {
    margin-bottom: 13px;
  }
}

.no-margin {
  width: 100%;
  
  & button {
    margin: 0 !important;
    padding: 10px !important;
    border: none !important;
  }
} 

.card-body {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  @include themify($themes) {
    color: themed('colorText');
  }
  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    z-index: 99;
  }
}

#table_filter > label {
  margin-right: 13px;
  
  & > input[type=search] {
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }
}

#table_length > label {
  margin-left: 13px;

  & > select {
    padding: 8px;
    border-radius: 3px;
    margin-right: 13px;
    border: 1px solid #cccccc;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #9c9c9c !important;
  background-color: #cccccc !important;
  background: #cccccc !important;
  color: black !important;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 1px solid #afafaf;
}

table.dataTable tfoot th, table.dataTable tfoot td {
  border-top: 1px solid #afafaf;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #afafaf;
}

#table_info {
  margin-left: 13px;
}

#table_paginate {
  margin-right: 13px;
}

#table_wrapper {
  margin-bottom: 21px;
}

.justify-content-center row {
  h1, h2, h3, h4, h5, h6{
    line-height: 3em !important;
  }
}

.padding-top-21 {
  padding-top: 21px !important;
}

.margin-bottom-21 {
  margin-bottom: 21px !important;
}

.padding-34 {
  padding: 34px !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.list-group-item {
  background: none;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.fullDeviceHeight {
  height: calc(100vh - 8em);
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
}
